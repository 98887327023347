<template>
    <div class="inbox">
      <h1>Inbox</h1>
      <p>Your messages will appear here.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Inbox'
  }
  </script>
  
  <style scoped>
  .inbox {
    padding: 20px;
  }
  </style>